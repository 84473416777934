@use "../../sass/stylesImports.scss" as styles;

.about-us {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  padding-top: 7rem;
  position: relative;

  &__title-wrapper {
    position: relative;
    display: inline-block;
  }

  &__title {
    font-size: styles.$heading-size-16;
    text-transform: uppercase;
    text-shadow: 4px 6px 4px #000000;
  }
  &__sub-title {
    font-size: styles.$heading-size-16;
    width: 100%;
  }
  &__data-container {
    padding: 5rem 7rem;
    width: 100%;
    @include styles.flex(column, nowrap, center, center);
  }
  &__description {
    font-size: styles.$heading-size-16;
    width: 100%;
    // text-align: center;
  }
  &__values-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  &__values-item {
    font-size: styles.$heading-size-16;
  }
  &__values-item-description {
    font-size: styles.$text-size-13;
    color: white;
    width: 95%;
  }
  //graphics
  &__graphics {
    &__dots {
      position: absolute;
      top: 60%;
      left: 20%;
      z-index: -1;
    }
    &__ring {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      opacity: 0.2;
      width: 30%;
    }
  }
}
@media (max-width: 820px) {
  .about-us {
    &__graphics {
      &__ring {
        width: 50%;
      }
    }
  }
}
@media (max-width: 600px) {
  .about-us {
    &__data-container {
      padding: 2rem;
    }

    &__values-container {
      grid-template-columns: 1fr;
    }
  }
}
