body {
  font-family: "ChakraPetch", sans-serif;
  color: $color-text-primary;
}

.heading-primary {
  /*h1*/
  color: $color-primary;
  font-family: "ChakraPetch", sans-serif;

  &--main {
    color: $color-secondary;
    font-size: $heading-size-44;
    font-weight: 800;
  }

  &--upper {
    text-transform: uppercase;
  }
}
.heading-secondary {
  /*h1*/
  color: $color-tertiary;
  font-family: "ChakraPetch", sans-serif;

  &--main {
    font-size: $heading-size-26;
    font-weight: 800;
  }
  &--upper {
    text-transform: uppercase;
  }
}
.highlighted {
  color: $color-primary;
}
