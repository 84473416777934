@use "../../sass/stylesImports.scss" as styles;
.nav {
  @include styles.flex(row, nowrap, start, center);
  position: fixed;
  top: 0;
  left: 0;
  height: 6rem;
  width: 100%;
  gap: 2rem;
  padding: 1rem 0rem;
  // background-color: styles.$color-secondary;
  background: linear-gradient(
    180deg,
    styles.$color-secondary 0%,
    #00000000 100%
  );
  //   background: linear-gradient(
  //     180deg,
  //     #000 0%,
  //     rgba(0, 0, 0, 0.71) 56.41%,
  //     rgba(0, 0, 0, 0.53) 75.52%,
  //     rgba(0, 0, 0, 0) 100%
  //   );

  z-index: 9;
  &__link {
    font-size: styles.$text-size-10;
    font-weight: 500;
    color: styles.$color-text-primary;

    &--active {
      color: styles.$color-tertiary !important;
      font-weight: 600;
    }
  }
  &__logo {
    height: 100%;
    margin: 0rem 2rem;
  }
}
