@use "../../sass/stylesImports.scss" as styles;

.contact {
  height: 100vh;
  width: 100%;
  padding: 2rem;
  padding-top: 7rem;
  position: relative;
  @include styles.flex(column, nowrap, center, center);

  &__info-container {
    &__item {
      @include styles.flex(row, nowrap, start, center);
      gap: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  //graphics
  &__graphics {
    &__show-control {
      width: 8rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 3rem;
    }
    &__line {
      margin-top: 2rem;
    }
    &__show-control-bg {
      position: absolute;
      z-index: -1;
      opacity: 0.2;
      width: 100%;
      bottom: 0;
    }
  }
}
