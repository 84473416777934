//PARTY USER LAYOUTS
.layout-primary {
  width: auto;
  margin: 0 2rem;
  z-index: 1;
  position: relative;
  // border: 1px solid orange;
  animation: fadein 0.2s ease-in;
}
.gradient-line {
  height: 1px;
  width: 100%;
  background: linear-gradient(90deg, #039fc5 0%, #f00480 100%);
}
