/* Root style, reference for REM unit */
html {
  // color-scheme: light dark;
  // 1rem setup
  font-size: 62.5%;

  @include respond(tab-port) {
    font-size: 70%; // 1rem = 11.2px -> 8/16
  }

  @include respond(tab-land) {
    font-size: 75%; //1rem = 12px -> 12/16
  }

  @include respond(big-desktop) {
    font-size: 87.5%; //1rem = 14px -> 14/16
  }
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  background-color: $color-secondary;
  min-height: 100vh;
  max-width: 100vw;
  scroll-behavior: smooth;
  /* Hide scrollbar for Chrome, Safari and Opera */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #b3b3b3 #58585898;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #58585898;
}

*::-webkit-scrollbar-track:hover {
  background-color: #58585898;
}

*::-webkit-scrollbar-track:active {
  background-color: #58585898;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #b3b3b3;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #b3b3b3;
}
