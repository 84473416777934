@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes gradient-color {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}

@keyframes traslateY-0 {
  0% {
    transform: translateY(110%);
  }
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px); /* Cambia la distancia del rebote */
  }
  60% {
    transform: translateY(-10px); /* Cambia la distancia del rebote */
  }
}

@keyframes breathing {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes moveText {
  0% {
    transform: translateX(
      0%
    ); /* Comienza fuera del área visible a la izquierda */
  }
  50% {
    transform: translateX(-20%); /* Llega a un punto intermedio y rebota */
  }
  100% {
    transform: translateX(0%); /* Vuelve al punto de partida */
  }
}
