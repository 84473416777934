//BUTTON CLASSES

.btn {
  width: 100%;
  height: 6rem;
  border-radius: 1.6rem;
  border: none;
  font-size: 1.6rem;
  font-family: Moncerrat, sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  &--primary {
    background: $color-secondary;
    color: $color-primary;
    &:visited {
      color: $color-primary;
    }
  }
  &--secondary {
    background: $color-primary;
    color: $color-secondary;
    &:visited {
      color: $color-secondary;
    }
  }
}
