@use "../../sass/stylesImports.scss" as styles;

.multimedia-viewer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: #000000ea;
  z-index: 9;

  &--show {
    @include styles.flex(row, nowrap, center, center);
    animation: fadein 0.5s 1 forwards;
  }
  &--hidden {
    display: none;
    animation: fadeout 0.5s 1 forwards;
  }
  &__close-overlay {
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  &__container {
    width: 85%;
    height: 90%;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    border: solid 1px #00f4f418;

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 3rem;
      height: 3rem;
      z-index: 3;
      font-size: styles.$text-size-12;
      color: styles.$color-tertiary;
      border-radius: 50%;
      background: #000000dc;
    }
    &__swipper-container {
      width: 100%;
      height: 100%;
    }
    &___multimedia-container {
      width: 100%;
      height: 100%;
      overflow: hidden;

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  //swiper
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: styles.$color-tertiary;
    font-size: styles.$text-size-12;
    min-width: 3.5rem !important;
    min-height: 3.5rem !important;
    border-radius: 50%;
    background: #000000dc;
    @include styles.flex(column, nowrap, center, center);
  }
  .swiper-button-next:after {
    margin-right: 3rem;
  }
  .swiper-button-prev:after {
    margin-left: 3rem;
  }
}
@media (max-width: 820px) {
  .multimedia-viewer {
    &__container {
      height: 70%;
    }
  }
}
@media (max-width: 600px) {
  .multimedia-viewer {
    &__container {
      height: 50%;
    }
  }
}
