//COLORS

$color-primary: #00f4f4;
$color-secondary: #000000;
$color-tertiary: #f0047f;
$color-text-primary: #fff;
$color-text-light: #727589;

//FONT SIZE

/*GENERAL*/
$text-size-18: 1.8rem; //18px
$text-size-16: 1.6rem; //16px
$text-size-14: 1.4rem; //14px
$text-size-13: 1.3rem; //14px
$text-size-12: 1.2rem; //12px
$text-size-10: 1rem; //10px
$text-size-9: 0.9rem; //10px
/*HEADINGS-TITLES*/
$heading-size-44: 4.4rem;
$heading-size-35: 3.5rem;
$heading-size-32: 3.2rem; //32px
$heading-size-26: 2.6rem; //28px
$heading-size-24: 2.4rem; //24px
$heading-size-20: 2rem; //20px
$heading-size-18: 1.8rem; //18px
$heading-size-16: 1.6rem; //16px

// FONT FAMILIES
$font-body: "Acumin Pro Wide", sans-serif;

//BORDER RADIUS
/*GENERAL-CONTAINERS*/
$border-radius-primary: 2rem; //20px
