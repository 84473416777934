@use "../../sass/stylesImports.scss" as styles;

.home {
  //   border: solid 1px aqua;
  width: 100%;
  height: 100vh;
  position: relative;
  @include styles.flex(column, nowrap, center, center);

  &__title {
    text-align: center;
    font-size: styles.$heading-size-35;
  }
  &__description {
    text-align: center;
    font-size: styles.$text-size-14;
    width: 60%;
  }
  &__info-container {
    @include styles.flex(column, nowrap, center, center);
    // position: relative;
    // border: solid 1px aqua;
    max-width: 80%;
  }
  &__title-wrapper {
    // border: solid 1px aqua;

    position: relative;
  }
  //graphics
  &__double-ring {
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    z-index: -1;
    filter: drop-shadow(5px 5px 23px #000000);
  }
  &__show-control {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0.7;
  }
  &__slice-ring {
    position: absolute;
    width: 18%;
    left: 0;
    bottom: 5%;
    z-index: -1;
  }
  &__dots {
    position: absolute;
    width: 8rem;
    top: -0.5rem;
    left: -2rem;
    z-index: -1;
  }
}

@media (max-width: 820px) {
  .home {
    //graphics
    &__slice-ring {
      width: 40%;
    }
    &__double-ring {
      width: 50%;
    }
  }
}

@media (max-width: 600px) {
  .home {
    &__description {
      width: 90%;
    }
    &__info-container {
      width: 90%;
    }

    //graphics
    &__show-control {
      display: none;
    }
  }
}
