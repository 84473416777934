/******** 
Moncerrat
********/

@font-face {
  font-family: "Moncerrat";
  src: url("./Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Moncerrat";
  src: url("./Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Moncerrat";
  src: url("./Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Moncerrat";
  src: url("./Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

/******** 
ChakraPetch
********/

@font-face {
  font-family: "ChakraPetch";
  src: url("./Chakra-Petch/ChakraPetch-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "ChakraPetch";
  src: url("./Chakra-Petch/ChakraPetch-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "ChakraPetch";
  src: url("./Chakra-Petch/ChakraPetch-SemiBold.ttf") format("truetype");
  font-weight: 700;
}
