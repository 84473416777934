.input {
  border: none;
  background-color: $color-primary;
  color: $color-secondary;
  font-size: $text-size-14;
  width: 100%;
  height: 5.5rem;
  border-radius: 3rem;
  padding: 0rem 2.5rem 0rem 6rem;
  vertical-align: bottom;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #ffffffa9;
  }
}
